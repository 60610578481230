import * as React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import store from 'store';
import { Provider as StoreProvider } from 'react-redux';
import { QueryClientProvider } from './query-client';

function AppProviders({ children }) {
  return (
    <StoreProvider store={store}>
      <QueryClientProvider>
        <Router>
          {children}
        </Router>
      </QueryClientProvider>
    </StoreProvider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AppProviders;
